<template>
  <div>
    <icon-mark
      class="mr-2"
      :x-small="xSmall"
      :small="small"
      :normal="normal"
      :large="large"
      :x-large="xLarge"
      :color="computedColor"
      v-if="visible"
      :icon-name="iconName"
      :icon-mark-props="iconMarkProps"
    />
  </div>
</template>

<script>
export default {
  components: {
    IconMark: () => import('@/components/icon-mark')
  },
  computed: {
    computedColor () {
      return /[0-9a-f]{6}/i.test(this.color) ? `#${this.color}` : this.color
    }
  },
  data () {
    return {
    }
  },
  props: {
    color: String,
    iconName: String,
    visible: Boolean,
    xSmall: Boolean,
    small: Boolean,
    normal: Boolean,
    large: Boolean,
    xLarge: Boolean,
    mixed: Boolean,
    iconMarkProps: Object
  }
}
</script>

<style lang="stylus" scoped></style>
